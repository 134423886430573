'use strict'

import $ from 'jquery';
import _ from 'lodash';
import state from '../util/state.js';

const Link = class {
  constructor() {
    const slink = $('.js-slink');
    const flink = $('.js-flink');

    slink.each(function(){
      $(this).on('click', function(e){
        e.preventDefault();

        let href = $(this).attr('href'),
          target = $(href == '#' || href == '' ? 'html' : href),
          position = target.offset().top - 80;
        position = (position > 0) ? position - 0 : position;
        $('body,html').animate({scrollTop: position}, 800);
        return false;
      });
    });

    flink.each(function(){
      $(this).on('click', function(e){
        e.preventDefault();

        const url = $(this).attr('href');
        const hash = location.hash;

        if (url != '') {
          
          // console.log('target href:' + url);
          // console.log('target hash:' + hash);
          // console.log('location.href:' + location.href);

          if(url.indexOf('#') > 0 && ((location.href.indexOf('inspirations') > 0 && url.indexOf('inspirations') > 0) || (location.href.indexOf('showroom') > 0 && url.indexOf('showroom') > 0) )) {

            var target = $(url.substr(url.indexOf('#'))),
                position = target.offset().top;
            // position = (position > 0) ? position - 0 : position;
            $('body,html').animate({scrollTop: position}, 800);

            if(state.dev === 'pc') {
              var hd = document.querySelector('header.global');
              hd.classList.remove('current');
              hd.classList.remove('isHover');
              $('.js-nav-bg .img').removeClass('current');
              document.querySelector('.js-nav-bg').style.backgroundColor = 'rgba(0,0,0,0)';
              state.nav = false;
            } else {
              location.href = url;
              setTimeout(function(){
                location.reload();
              }, 300);
            }

          } else {

            $('#wrap').addClass('fout');
            setTimeout(function(){
              location.href = url;
            }, 1000);
          }
        }
        return false;
      });
    });
  }
}

export default Link;
