'use strict'

import _ from 'lodash';
import state from '../util/state';

const Header = class {
  constructor() {
    this.tgt = {
			hd: document.querySelector('header.global'),
      // hdH: document.querySelector('header.global').offsetHeight,
      hdH: 65,
      pos: 0,
      sb: document.querySelector('.search_box')
    }
    window.addEventListener('scroll', _.throttle(this.scrl.bind(this), 200));
    this.scrl();
  }
  scrl() {
    if(state.dev === 'pc' && state.nav === false) {
      const scroll = window.pageYOffset;

      if(scroll > this.tgt.hdH) {
        this.tgt.hd.classList.add('isBg');
      } else {
        this.tgt.hd.classList.remove('isBg');
      }

      if(scroll > this.tgt.pos && scroll > this.tgt.hdH) {
        this.tgt.hd.style.top = '-' + this.tgt.hdH + 'px';
      } else {
        this.tgt.hd.style.top = 0;
      }
      this.tgt.pos = scroll;
    }
  }
};
export default Header;
