'use strict'

import $ from 'jquery';
import state from '../util/state.js';

const Footer = class {
  constructor(el) {
    const tgt = $(el);

    tgt.each(function(){
      const tgl = this,
            btn = $(tgl).find('.js-tgl-btn'),
            hd = $(tgl).find('.js-tgl-hd'),
            bd = $(tgl).find('.js-tgl-bd');

      $(hd).on('click', function(e){
        $(tgl).toggleClass('current');
        $(btn).toggleClass('current');
        $(bd).slideToggle();
      });

    });
  }
};
export default Footer;
