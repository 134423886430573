import './polyfill'
// import $ from 'jquery';
import Header from './modules/Header';
import HeaderNav from './modules/HeaderNav';
import Footer from './modules/Footer';

import Top from './modules/Top';
import Product from './modules/Product';
import ConceptLineup from './modules/ConceptLineup';
import Inspiration from './modules/Inspiration';


import Inview from './modules/Inview';
// import Wrapper from './modules/Wrapper';

import Contact from './modules/Contact';

import Link from './modules/Link';

import state from './util/state';
state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

window.addEventListener('DOMContentLoaded', function() {
  new Header();
  new HeaderNav();
  new Link();
  new Inview();

  if(state.dev === 'sp') {
    new Footer('.js-tgl');
  }
  if(document.querySelector('body.products')) {
    new Product();
  }
  if(document.querySelector('body.concept_lineup')) {
    new ConceptLineup();
  }
  if(document.querySelector('body.inspirations')) {
    new Inspiration();
  }
  if(document.querySelector('#contact_form')) {
    new Contact();
  }

  if(!document.querySelector('body.index')) {
    document.querySelector('#wrap').classList.add('ready');
  } else {
    setTimeout(function() {
      document.querySelector('#wrap').classList.add('ready');
    }, 500);
  }
});

window.onload = function(){
  if(state.dev === 'pc') {
    // new Wrapper();
  }
  if(document.querySelector('body.index')) {
    new Top();
  }
};

window.addEventListener('resize', _.throttle(function() {
  state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';
}, 500));
