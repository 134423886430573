'use strict'

import $ from 'jquery';
import _ from 'lodash';

const Contact = class {
  constructor() {
    this.ckTgl();
    this.ckPlaceTgl();
    this.ckSubmit();
  }
  ckTgl() {
    const tgl = $('.js-rd-tgl'),
          tck = $('.js-rd'),
          rds = $('.js-rds .rd');

    $(rds).click(function() {
      // if($(tck).is(':checked')) {
      if($(tck).prop('checked')) {
        
        $(tgl).slideDown();
        $('.js-rd-tgl .js-req').prop('required', 'required');
        console.log('check in');

      } else {
        console.log('check out');
        $(tgl).slideUp();
        $('.js-rd-tgl .js-req').prop('required', '');
      }
    });
    
  }

  ckPlaceTgl() {
    $('[name=think]').on('change', function() {
      const v = $('[name=think]:checked').val();
      if ( v == 'think-01' || v == 'think-02' ) {
        $('.js-place-tgl').slideDown();
      } else {
        $('.js-place-tgl').slideUp();
      }
    });
  }

  ckSubmit() {
    const form = document.getElementById('contact_form');
    const submit = document.getElementById('submitBtn');

    form.addEventListener('submit', (e) => {
      e.preventDefault();

      const isValid = form.checkValidity();
      // if (isValid && $('.js-consent').prop('checked')) {
      if (isValid) {
        // OK
        document.contact_form.submit();
      } else {
        // NG
        $('tr').removeClass('err');
        $('p.err_m').slideUp();

        // check: radio
        $('.rds.js-req').each(function(idx) {
          var rdFlag = false;
          const radios = $(this).find('input[type=radio]'),
                eMes = $(this).next('p.err_m');

          $(radios).each(function(idx) {
            // rdFlag = $(this).prop('checked');
            if($(this).prop('checked')) {
              rdFlag = true;
            }
            if(!rdFlag) {
              eMes.slideDown();
            } else {
              eMes.slideUp();
            }
          });
        });

        // check:input text
        $('.its.js-req').each(function(idx) {
          var itFlag = false;
          const its = $(this).find('.it'),
                eMes = $(this).next('p.err_m');

          $(its).each(function(idx) {

            if(this.value !== '') {
              // itFlag = true;
            } else {
              eMes.slideDown();
              $(this).parents('tr').addClass('err');
            }
          });
        });

        $('input.js-req, textarea.js-req').each(function(idx) {
          if(this.value === '') {
            $(this).next('p.err_m').slideDown();
            $(this).parents('tr').addClass('err');
          }
        });

        // check: consent
        if(!$('.js-consent').prop('checked')) {
          $('.consent p.err_m').slideDown();
        }

        // scroll
        $('body,html').animate({scrollTop: 0}, 800);
      }
    });
  }
};

$(window).on("load", function() {

  var _radio = [];
	$('input.check-consent-01').on('click', function() {
		var _this = $(this);
		var _name = _this.attr('name');
		var _val  = _this.val();
		
		if (_radio[_name] === '' || _radio[_name] === null || _radio[_name] === undefined) {
			_radio[_name] = _val;
		} else {
			if (_radio[_name] == _val) {
				_this.prop('checked', false);
				_radio[_name] = '';
			} else {
				_radio[_name] = _val;
			}
		}
	});
  const v = $('[name=think]:checked').val();
  if ( v == 'think-01' || v == 'think-02' ) {
    $('.js-place-tgl').slideDown();
  } else {
    $('.js-place-tgl').slideUp();
  }
  const tgl = $('.js-rd-tgl'),
        tck = $('.js-rd'),
        rds = $('.js-rds .rd');

  if($(tck).prop('checked')) {

    $(tgl).slideDown();
    $('.js-rd-tgl .js-req').prop('required', 'required');
    console.log('check in');

  } else {
    console.log('check out');
    $(tgl).slideUp();
    $('.js-rd-tgl .js-req').prop('required', '');
  }

});
export default Contact;
