'use strict'

import $ from 'jquery';
import _ from 'lodash';
// import Swiper from 'swiper';
import Swiper from '../../../node_modules/swiper/dist/js/swiper.js';
// import Hammer from 'hammerjs';
import state from '../util/state';

const Inspiration = class {
  constructor() {

    if(state.dev === 'pc') {
      this.insSwiper();
    }
  }
  insSwiper() {

    const thumbVar = {
            effect: 'slide',
            speed: 1200,
            loop: true,
            spaceBetween: 15,
            slidesPerView: 4.5,
            centeredSlides: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
              nextEl: '.swiper-button-thumbnext',
              prevEl: '.swiper-button-thumbprev'
            }
          };

    const main = document.querySelector('.ins_swiper .slider_main'),
          thumb = document.querySelector('.ins_swiper .slider_thumb');

    const thumbSwiper = new Swiper (thumb, thumbVar),
          mainSwiper = new Swiper (main, {
            effect: 'slide',
            speed: 1200,
            loop: true,
            centeredSlides: true,
            autoplay: {
              delay: 4000
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            thumbs: {
              swiper: thumbSwiper
            }
          });


    if(document.querySelector('.ins_swiper2')) {
      const main2 = document.querySelector('.ins_swiper2 .slider_main'),
            thumb2 = document.querySelector('.ins_swiper2 .slider_thumb');

      const thumbSwiper2 = new Swiper (thumb2, {
              effect: 'slide',
              speed: 1200,
              // loop: true,
              spaceBetween: 15,
              slidesPerView: 4.5,
              // centeredSlides: true,
              watchSlidesVisibility: true,
              watchSlidesProgress: true,
              navigation: {
                nextEl: '.swiper-button-thumbnext',
                prevEl: '.swiper-button-thumbprev'
              }
            }),
            mainSwiper2 = new Swiper (main2, {
              effect: 'slide',
              speed: 1200,
              loop: true,
              centeredSlides: true,
              autoplay: {
                delay: 4000
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              thumbs: {
                swiper: thumbSwiper2
              }
            });
    }

    if(document.querySelector('.ins_swiper3')) {
      const main3 = document.querySelector('.ins_swiper3 .slider_main'),
            thumb3 = document.querySelector('.ins_swiper3 .slider_thumb');

      const thumbSwiper3 = new Swiper (thumb3, thumbVar),
            mainSwiper3 = new Swiper (main3, {
              effect: 'slide',
              speed: 1200,
              loop: true,
              centeredSlides: true,
              autoplay: {
                delay: 4000
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              thumbs: {
                swiper: thumbSwiper3
              }
            });
    }
  }
};
export default Inspiration;
