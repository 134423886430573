'use strict'

import $ from 'jquery';
import _ from 'lodash';
// import Swiper from 'swiper';
import Swiper from '../../../node_modules/swiper/dist/js/swiper.js';
// import Hammer from 'hammerjs';
import state from '../util/state';

const ConceptLineup = class {
  constructor() {

    if(state.dev === 'pc') {
      this.prdSwiper();
      this.conceptSwiper();

      if(document.querySelector('.uibtn')) {
        this.prdSwitch();
      }
    }
  }
  prdSwiper() {
    const thumbVar = {
      effect: 'slide',
      speed: 900,
      // loop: true,
      spaceBetween: 25,
      slidesPerView: 10,
      // freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    };


    const main = document.querySelector('.prd_swiper .slider_main'),
          thumb = document.querySelector('.prd_swiper .slider_thumb');

    const thumbSwiper = new Swiper (thumb, thumbVar),
          mainSwiper = new Swiper (main, {
            effect: 'slide',
            speed: 900,
            loop: true,
            centeredSlides: true,
            spaceBetween: 100,
            autoplay: {
              delay: 8000
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            on: {
              slideChangeTransitionEnd: function () {
                $('.prd_swiper .slider_main .slider_item').removeClass('current');
                $('.prd_swiper .slider_main .slider_item.swiper-slide-duplicate-active,  .prd_swiper .slider_main .slider_item.swiper-slide-active').addClass('current');
              },
            },
            thumbs: {
              swiper: thumbSwiper
            }
          });

    if(document.querySelector('.prd_swiper2')) {
      const main2 = document.querySelector('.prd_swiper2 .slider_main'),
            thumb2 = document.querySelector('.prd_swiper2 .slider_thumb');

      const thumbSwiper2 = new Swiper (thumb2, thumbVar),
            mainSwiper2 = new Swiper (main2, {
              effect: 'slide',
              speed: 900,
              loop: true,
              centeredSlides: true,
              spaceBetween: 100,
              autoplay: {
                delay: 4000
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              on: {
                slideChangeTransitionEnd: function () {
                  $('.prd_swiper2 .slider_main .slider_item').removeClass('current');
                  $('.prd_swiper2 .slider_main .slider_item.swiper-slide-duplicate-active,  .prd_swiper2 .slider_main .slider_item.swiper-slide-active').addClass('current');
                },
              },
              thumbs: {
                swiper: thumbSwiper2
              }
            });
    }

    if(document.querySelector('.prd_swiper3')) {
      const main3 = document.querySelector('.prd_swiper3 .slider_main'),
            thumb3 = document.querySelector('.prd_swiper3 .slider_thumb');

      const thumbSwiper3 = new Swiper (thumb3, thumbVar),
            mainSwiper3 = new Swiper (main3, {
            effect: 'slide',
            speed: 900,
            loop: true,
            centeredSlides: true,
            spaceBetween: 100,
            autoplay: {
              delay: 4000
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            on: {
              slideChangeTransitionEnd: function () {
                $('.prd_swiper3 .slider_main .slider_item').removeClass('current');
                $('.prd_swiper3 .slider_main .slider_item.swiper-slide-duplicate-active,  .prd_swiper3 .slider_main .slider_item.swiper-slide-active').addClass('current');
              },
            },
            thumbs: {
              swiper: thumbSwiper3
            }
          });
    }

    if(document.querySelector('.prd_swiper4')) {
      const main4 = document.querySelector('.prd_swiper4 .slider_main'),
            thumb4 = document.querySelector('.prd_swiper4 .slider_thumb');

      const thumbSwiper4 = new Swiper (thumb4, thumbVar),
            mainSwiper4 = new Swiper (main4, {
              effect: 'slide',
              speed: 900,
              loop: true,
              centeredSlides: true,
              spaceBetween: 100,
              autoplay: {
                delay: 4000
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              on: {
                slideChangeTransitionEnd: function () {
                  $('.prd_swiper4 .slider_main .slider_item').removeClass('current');
                  $('.prd_swiper4 .slider_main .slider_item.swiper-slide-duplicate-active,  .prd_swiper4 .slider_main .slider_item.swiper-slide-active').addClass('current');
                },
              },
              thumbs: {
                swiper: thumbSwiper4
              }
            });
    }
  }

  conceptSwiper() {
    const thumbVar = {
      effect: 'slide',
      speed: 900,
      // loop: true,
      spaceBetween: 25,
      slidesPerView: 10,
      // freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    };
    
    if(document.querySelector('.concept_swiper')) {
      console.log('concept slider');

      const mainC = document.querySelector('.concept_swiper .slider_main'),
            thumbC = document.querySelector('.concept_swiper .slider_thumb');

      const thumbSwiperC = new Swiper (thumbC, thumbVar),
            mainSwiperC = new Swiper (mainC, {
              effect: 'slide',
              speed: 900,
              loop: true,
              centeredSlides: true,
              spaceBetween: 100,
              autoplay: {
                delay: 4000
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              on: {
                slideChangeTransitionEnd: function () {
                  $('.concept_swiper .slider_main .slider_item').removeClass('current');
                  $('.concept_swiper .slider_main .slider_item.swiper-slide-duplicate-active,  .concept_swiper .slider_main .slider_item.swiper-slide-active').addClass('current');
                },
              },
              thumbs: {
                swiper: thumbSwiperC
              }
            });
    }
  }

  prdSwitch() {
    const switchBtn = document.querySelectorAll('.uibtn .js-uibtn');
    var lineupGrid = false;

    switchBtn.forEach(function(btn) {
      btn.addEventListener('click', e => {
        const tgt = btn.getAttribute('data-ui');

        if(tgt === 'prd_swiper') {
          $('.uibtn .js-uibtn').removeClass('current');
          $('.uibtn .js-uibtn[data-ui=prd_swiper]').addClass('current');
          $('#prd_swiper').slideDown(800);
          $('#prd_grid').slideUp(800);
          lineupGrid = false;

        } else if(tgt === 'prd_grid') {
          $('.uibtn .js-uibtn').removeClass('current');
          $('.uibtn .js-uibtn[data-ui=prd_grid]').addClass('current');
          $('#prd_swiper').slideUp(800);
          $('#prd_grid').slideDown(800);
          lineupGrid = true;
        }
      });
    });

    window.addEventListener('scroll', _.throttle(function() {

      const scroll   = window.pageYOffset,
            lineupHd = document.querySelector('.lineup_hd'),
            prdGrid  = document.querySelector('.prd_grid'),
            prdGridH = prdGrid.clientHeight,
            prdGridPos = prdGrid.getBoundingClientRect().top;

      if(prdGridPos < 220 && prdGridPos > (prdGridH - 140) * -1) {
        lineupHd.classList.add('isFixed');
      } else {
        if(prdGridPos < 0) {
          lineupHd.classList.add('h0');
          setTimeout(function() {
            lineupHd.classList.remove('h0');
            lineupHd.classList.remove('isFixed');
          },400);
        } else {
          lineupHd.classList.remove('isFixed');
        }
      }
    }, 100));
  }
};
export default ConceptLineup;
