'use strict'

import $ from 'jquery';
import _ from 'lodash';
// import Swiper from 'swiper';
import Swiper from '../../../node_modules/swiper/dist/js/swiper.js';
// import Hammer from 'hammerjs';
import fullpageOverflow from '../../../node_modules/fullpage.js/vendors/scrolloverflow.min.js';
// import fullpage from 'fullpage.js';
import state from '../util/state';

const Top = class {
  constructor() {

    this.fvSwiper();
    this.prSwiper();
    this.inSwiper();

    if(state.dev === 'pc') {

      // new fullpage('#fullpage', {
      // 	//options here
      // 	// autoScrolling: true,
      //   // licenseKey: '171E77F4-3D7E4091-90F34C07-6F0849A8',
      //   // licenseKey: 'E7611ACF-CA8840A3-B33EA45F-7AA25A58',
      //   licenseKey: 'E6247369-42D04087-88095BDE-C415B851',
      //   fadingEffect: true,
      //   fadingEffectKey: 'a2FqaWwuY29fMmhVWm1Ga2FXNW5SV1ptWldOMHpNdg==',
      // 	scrollHorizontally: true,
      //   scrollOverflow: true,
      //   css3: false,
	    //   scrollingSpeed: 1000,
      //   easing: 'easeInOutCubic',
	    //   // easingcss3: 'ease',
      //   // anchors: ['top', 'front', 'modal'],
      //
      //   afterLoad: function(origin, destination, direction){
      //     var loadedSection = this;
      //
      //     $('.section.active .js-in-pc').addClass('inview');
      //
      //     if(destination.index === 0) {
      //       $('header.global').removeClass('isBgOp');
      //       $('header.global').removeClass('isBg');
      //       $('header.global').css('top', 0);
      //     } else if(direction === 'up') {
      //       $('header.global').removeClass('isBgOp');
      //       $('header.global').addClass('isBg');
      //       $('header.global').css('top', 0);
      //     } else {
      //       $('header.global').addClass('isBgOp');
      //       $('header.global').css('top', '-64px');
      //     }
      //
      //     if(destination.index > 0 && destination.index < 5) {
      //       $('.concept-fixed p.title, .concept-fixed .num-pc').addClass('active');
      //       $('#js-concept_num').text('0' + destination.index);
      //
      //       // other texts for concept
      //       $('.section.active .js-in-pc2').addClass('inview');
      //     }
      //   },
      //   onLeave: function(origin, destination, direction){
      //     if(destination.index < 1 || destination.index > 4) {
      //       $('.concept-fixed p.title, .concept-fixed .num-pc').removeClass('active');
      //       $('.section.active .js-in-pc2').removeClass('inview');
      //     }
      //   }
      // });
    }
    if(state.dev === 'sp') {
      this.spChange();
    }
  }
  fvSwiper() {
    const fv = document.querySelector('.fv_slide'),
          spd = (state.dev === 'pc') ? 700 : 600;

    const fvSwiper = new Swiper (fv, {
      effect: 'fade',
      speed: spd,
      loop: true,
      centeredSlides: true,
      speed: 1200,
      autoplay: {
        delay: 4000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }
  prSwiper() {
    const fv = document.querySelector('.product_slide'),
          per = (state.dev === 'pc') ? 3.3 : 1,
          spd = (state.dev === 'pc') ? 800 : 600,
          spc = (state.dev === 'pc') ? 0 : 25,
          mod = (state.dev === 'pc') ? true : false;

    const fvSwiper = new Swiper (fv, {
      effect: 'slide',
      speed: spd,
      slidesPerView: per,
      loopedSlides: per,
      spaceBetween: spc,
      // loop: true,
      centeredSlides: false,
      freeMode: mod,
      freeModeSticky: mod,
      autoplay: {
        delay: 8000
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }
  inSwiper() {
    const fv = document.querySelector('.ins_slide'),
          spd = (state.dev === 'pc') ? 500 : 400,
          spc = (state.dev === 'pc') ? 45 : 0;

    const inSwiper = new Swiper (fv, {
      effect: 'slide',
      speed: spd,
      spaceBetween: spc,
      loop: true,
      centeredSlides: true,
      speed: 1200,
      // autoplay: {
      //   delay: 6000
      // },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        transitionEnd: function () {
          /* do something */
          $('.ins_slide .slider_item').removeClass('current');
          $('.ins_slide .swiper-slide-active').addClass('current');
        },
      }
    });
  }
  spChange() {
    const nav = document.querySelectorAll('.top_concept .js-sp-nav .sp_nav_item'),
          bd = document.querySelectorAll('.top_concept .item');

    function itemChange() {
      slide ++;
      if(slide > 3) {
        slide = 1;
      }
      // console.log('itemChange:' + slide);
      $('.top_concept .js-sp-nav .sp_nav_item').removeClass('current');
      $('.top_concept .js-sp-nav .sp_nav_item:nth-child(' + slide + ')').addClass('current');
      $('.top_concept .item').removeClass('current');
      $('.top_concept .item:nth-child(' + slide + ')').addClass('current');
    }
    var slide = 1,
        spConcept = setInterval(itemChange, 5000);

    nav.forEach(function(item) {
      item.addEventListener('click', e => {
        const id = item.getAttribute('data-sp-item');
        const num = id.substr(-1)
        slide = parseInt(num);


        bd.forEach(function(body) {
          body.classList.remove('current');
          document.getElementById(id).classList.add('current');
        });
        nav.forEach(function(n) {
          n.classList.remove('current');
          item.classList.add('current');
        });
      });
    });
  }
};
export default Top;
