'use strict'

import $ from 'jquery';
import _ from 'lodash';
import state from '../util/state.js';

const HeaderNav = class {
  constructor() {

    document.ontouchmove = function(e){ return true; }

    if(state.dev === 'pc') {
      this.drpNav();
    } else {
      this.tglNav();
    }

    window.addEventListener('resize', _.throttle(() => {
      if(state.dev === 'pc') {
        this.drpNav();
      } else {
        this.tglNav();
      }
    }, 500));
  }

  // for PC
  drpNav() {
    const nav = document.querySelectorAll('header.global .nav_main .nav_item'),
          hov = document.querySelector('header.global .nav_sub_bg'),
          hd = document.querySelector('header.global'),
          sub = document.querySelectorAll('header.global .nav_sub_list'),
          link = document.querySelectorAll('header.global a');

    // Open sub nav
    nav.forEach(function(item) {
      item.addEventListener('mouseenter', e => {
        const subId = item.getAttribute('data-nav-sub');

        if(subId != "none") {
          hd.classList.add('current');
          hd.classList.add('isHover');
          state.nav = true;

          sub.forEach(function(item) {
            item.classList.remove('current');
            document.getElementById(subId).classList.add('current');
          });
        } else {
          hd.classList.remove('current');
          hd.classList.remove('isHover');
          item.classList.remove('current');
          $('.js-nav-bg .img').removeClass('current');
          document.querySelector('.js-nav-bg').style.backgroundColor = 'rgba(0,0,0,0)';
          state.nav = false;
        }
      });
    });

    hov.addEventListener('mouseenter', e => {
      hd.classList.remove('current');
      hd.classList.remove('isHover');
      $('.js-nav-bg .img').removeClass('current');
      document.querySelector('.js-nav-bg').style.backgroundColor = 'rgba(0,0,0,0)';
      state.nav = false;
    });

    const snav = document.querySelectorAll('header.global .nav_sub_list .nav_item'),
          bgImg = $('.js-nav-bg .img');

    snav.forEach(function(item) {
      item.addEventListener('mouseenter', e => {
        var bgId = item.getAttribute('data-cbg');
        $('.js-nav-bg').removeClass('default');
        document.querySelector('.js-nav-bg').style.backgroundColor = '#1e1e1e';
        $(bgImg).removeClass('current');
        $('#' + bgId).addClass('current');
      });
    });
  }

  // for SP
  tglNav() {
    const tgl = document.querySelector('.js-header-nav'),
          hd = document.querySelector('header.global'),
          bd = document.querySelector('body');

    const sub = document.querySelectorAll('header.global .nav_sub_list'),
          pnav = document.querySelectorAll('header.global .js-sp-nav'),
          logo = document.querySelector('header.global .logo'),
          mnav = document.querySelector('header.global .nav_main'),
          snav = document.querySelector('header.global .nav_sub'),
          bnav = document.querySelector('header.global .nav_sub_back');

    tgl.onclick = function(e) {
      e.preventDefault();
      const btn = this;

      if(!state.tglOpen) {
        bd.classList.add('fixed');
        document.ontouchmove = function(e){ e.preventDefault(); }
        state.fixed = true;

        _.forEach({ btn, hd }, function(n, key) {
          n.classList.add('current');
        });
      } else {
        bd.classList.remove('fixed');
        document.ontouchmove = function(e){ return true; }
        state.fixed = false;

        _.forEach({ btn, hd, mnav, snav, logo }, function(n, key) {
          n.classList.remove('current');
          $('header.global .nav_sub_list').removeClass('current');
        });
      }
      state.tglOpen = !state.tglOpen;
    }

    pnav.forEach(function(item) {
      item.addEventListener('click', e => {
        const subId = item.getAttribute('data-nav-sub');

        sub.forEach(function(list) {
          list.classList.remove('current');
          document.getElementById(subId).classList.add('current');
        });
        _.forEach({ logo, mnav, snav }, function(n, key) {
          n.classList.add('current');
        });
      });
    });

    bnav.addEventListener('click', e => {
      sub.forEach(function(list) {
        list.classList.remove('current');
      });
      _.forEach({ logo, mnav, snav }, function(n, key) {
        n.classList.remove('current');
      });
    });
  }
};
export default HeaderNav;
